<template>
  <div class="detail-intro" :class="{skeleton:!state.loaded}">
    <div class="part info edit-style clearfix">
      <div :id="`${component.name}ProjectInfo`" class="wrapper" v-if="state.loaded">
        <a @click="registerCoupon('C0000616')" class="pointer" v-if="$route.params.projectSeq === '15164'">
          <img src="https://www.ohmycompany.com/uploads/reward/story/2024/04/15164/STORY_63e8a2317e5a.png" alt="쿠폰 다운받기">
        </a>
        <div class="coupons text-center" v-if="state.couponImages.length">
          <a @click="registerCoupon(c.couponId)" class="pointer" v-for="c in state.couponImages" :key="c.couponId">
            <img :src="c.url" alt="쿠폰 다운받기">
          </a>
        </div>
        <div class="stories" v-if="newEditorFlag === 'Y'">
          <template v-for="(s, idx) in rewardStoryStep3Tabs">
            <DetailIntroStory :step="s" :stories="state.projectStories[s.name]" :key="idx" v-if="state.projectStories[s.name] && state.projectStories[s.name].length"/>
          </template>
        </div>
        <div v-html="state.projectInfo" v-else></div>
      </div>
      <template v-else>
        <p>
          <span>Wait a moment</span>
        </p>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
        <br/>
        <p>
          <span>Please Wait a moment.</span>
        </p>
        <p>
          <span>Please Wait a moment. Thank you for waiting.</span>
        </p>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
      </template>
      <div class="product-info" v-if="state.productNotice && state.productNotice.trim()">
        <div class="title">
          <div class="inner">상품정보고시</div>
        </div>
        <div class="content" v-html="state.productNotice"></div>
      </div>
    </div>
    <div class="part add" v-if="simulationFlag !== 'Y' && state.addIntroList && state.addIntroList.length">
      <ul id="accordion" class="accordion tight">
        <li class="card" v-for="(a, idx) in state.addIntroList" :key="idx">
          <div class="card-header" :id="'detailIntro' + idx">
            <button class="btn btn-block font-sm collapsed" data-toggle="collapse" :data-target="`#collapse${idx}`" aria-expanded="true" :aria-controls="`#collapse${idx}`">
              <span>{{ a.title }}</span>
              <i class="fa fa-angle-down"></i>
            </button>
          </div>
          <div :id="`collapse${idx}`" class="collapse" :aria-labelledby="'detailIntro' + idx" data-parent="#accordion">
            <div class="card-body font-sm">{{ a.content }}</div>
          </div>
        </li>
      </ul>
    </div>
    <!--<div class="part process">
      <div class="cont-process">
        <h2 class="tit-process">
          <span>오마이컴퍼니 </span>
          <b class="txt-emph">후원형 프로젝트</b>
          <span> 참여 절차</span>
        </h2>
        <p class="txt-process">
          여러분들의 관심과 참여로 더 나은 세상을 만들어 나가는 프로젝트가
          실행됩니다.
        </p>
        <ol class="tight list-order">
          <li>
            <span class="img step step1" style="background-image:url(/assets/ico/page.project.detail.process.step1.png)"></span>
            <span class="txt-order">1. 회원가입</span>
            <i class="fa fa-angle-right"></i>
          </li>
          <li>
            <span class="img step step2" style="background-image:url(/assets/ico/page.project.detail.process.step2.png)"></span>
            <span class="txt-order">2. 프로젝트 선택</span>
            <i class="fa fa-angle-right"></i>
          </li>
          <li>
            <span class="img step step3" style="background-image:url(/assets/ico/page.project.detail.process.step3.png)"></span>
            <span class="txt-order">3. 참여하기</span>
            <i class="fa fa-angle-right"></i>
          </li>
          <li>
            <span class="img step step4" style="background-image:url(/assets/ico/page.project.detail.process.step4.png)"></span>
            <span class="txt-order">4. 결제</span>
            <i class="fa fa-angle-right"></i>
          </li>
          <li>
            <span class="img step step5" style="background-image:url(/assets/ico/page.project.detail.process.step5.png)"></span>
            <span class="txt-order">5. 리워드 수령</span>
          </li>
        </ol>
      </div>
    </div>-->
    <div class="comments" ref="commentRef">
      <DetailIntroComments :projectSeq="projectSeq" :projectType="projectType" :openId="openId"/>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import DetailIntroComments from "./DetailIntroComments.vue";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import DetailIntroStory from "@/pages/project/DetailIntroStory.vue";
import rewardStoryTabs from "@/scripts/rewardStoryTabs";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageProjectDetailIntro";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {DetailIntroStory, DetailIntroComments},
  props: {
    projectSeq: Number,
    projectType: String,
    simulationFlag: String,
    newEditorFlag: String,
    rewardItems: Array,
    openId: String,
  },
  setup(props) {
    const component = new Component(() => {
      load();
      loadCouponImages();
    });

    const state = reactive({
      projectInfo: null,
      projectStories: {
        fundingIntro: [],
        fundingPoint: [],
        rewardIntro: [],
        rewardConstructor: [],
        summery: [],
        plan: [],
        makerIntro: [],
      },
      couponImages: [],
      addIntroList: [],
      productNotice: "",
      loaded: false,
    });

    const rewardStoryStep3Tabs = rewardStoryTabs.step3.filter(r => r.name !== "fundingInfo" && r.name !== "fundingIntroTemplate");
    const commentRef = ref();

    const goComment = () => {
      const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      window.scrollTo({
        top: curScrollTop + commentRef.value.getBoundingClientRect().top - 70 - lib.getHeaderHeight(),
        left: 0,
        behavior: "smooth"
      });
    };

    const load = () => {
      const args = lib.isPreviewPage() ? {preview: "Y"} : undefined;

      state.loaded = false;
      http.get(`/api/${props.projectType}/projects/${props.projectSeq}/intro`, args).then(({data}) => {
        state.loaded = true;

        if (props.newEditorFlag === "Y") {
          state.projectStories = data.body.project.projectStories;
          state.projectStories.rewardConstructor = props.rewardItems.filter(r => r.rewardSeq);
        } else {
          state.projectInfo = data.body.project.projectInfo;
        }

        state.addIntroList = data.body.addIntroList;
        state.productNotice = data.body.project.productNotice;

        nextTick(() => {
          if (state.projectInfo) {
            store.commit("setImagePopupListener", {
              $parents: document.querySelectorAll(`#${component.name}ProjectInfo`),
              url: `/reward/${props.projectSeq}`,
              title: data.body.project.projectName,
            });
          }

          (($) => {
            const $youtubeIframes = $(`#${component.name}ProjectInfo iframe[src*="youtu"]`);
            const $reelsIframes = $(`#${component.name}ProjectInfo iframe[src*="/reel/"]`);

            $youtubeIframes.each(function () {
              if (!$(this).parent().hasClass("youtube-wrap-inner")) {
                $(this).wrap(`<div class="youtube-wrap${$(this).attr("src").includes("shorts=1") ? " shorts" : ""}"><div class="youtube-wrap-inner"></div></div>`);
              }
            });

            $reelsIframes.each(function () {
              $reelsIframes.attr("scrolling", "no");

              if (!$(this).parent().hasClass("reels-wrap-inner")) {
                $(this).wrap(`<div class="reels-wrap"><div class="reels-wrap-inner"></div></div>`);
              }
            });
          })(window.jQuery);
        });
      });
    };

    const loadCouponImages = () => {
      http.get(`/api/reward/projects/${props.projectSeq}/coupon-images`).then(({data}) => {
        state.couponImages = data.body.filter(b => b.couponId && b.url);
      });
    };

    const registerCoupon = (couponId) => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      http.post(`/api/coupons/${couponId}`, undefined, {message: false}).then(({data}) => {
        window.alert(data.message || "쿠폰이 발급되었습니다.");
      }).catch(httpError((err) => {
        err.response.data?.code !== "LOGIN_REQUIRED" && window.alert(err.response.data.message);
      }));
    };

    onUnmounted(() => {
      store.commit("setImagePopupListener", {
        $parents: document.querySelectorAll(`#${component.name}ProjectInfo`),
        destroy: true,
      });
    });

    return {component, state, commentRef, rewardStoryStep3Tabs, goComment, registerCoupon};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-intro {
  position: relative;

  .go-comment {
    position: absolute;
    top: $px1;
    right: $px1;
    border: 0;
    background: $colorBackground;
    text-align: center;
    padding: $px5;
    border-radius: 0 0 0 $px4;
    font-size: 70%;

    img {
      vertical-align: middle;
      margin-right: $px4;
    }

    span {
      vertical-align: middle;
    }
  }

  > .part {
    .product-info {
      margin-top: $px35;

      > .title {
        font-weight: 500;
        margin-bottom: $px25;
        border-bottom: $px1 solid #ddd;

        .inner {
          display: inline-block;
          font-size: $px18;
          background: #3c3c3c;
          border-radius: $px16 $px16 0 0;
          color: #fff;
          padding: $px5 $px30;
        }
      }
    }

    &.info::v-deep {
      overflow: hidden;

      > .wrapper {
        .stories {
          display: flex;
          flex-direction: column;
          gap: $px35;
        }

        table {
          table-layout: fixed;
        }
      }
    }

    &.process {
      padding-bottom: $px15 !important;

      .cont-process {
        text-align: center;

        .tit-process {
          margin-bottom: $px6;
          font-weight: normal;
          font-size: $px20;
          color: #767676;

          .txt-emph {
            color: $colorPoint;
          }
        }

        .txt-process {
          margin-bottom: $px20;
          font-size: $px14;
          color: #767676;
        }

        .list-order {
          overflow: hidden;
          text-align: center;
          margin: 0 $px-20;

          li {
            display: inline-block;
            position: relative;
            width: 20%;
            min-width: $px127;
            padding: $px20;
            vertical-align: top;

            > .step {
              margin-bottom: $px15;
              border-radius: 50%;
              background-color: #eaeaea;
              height: $px88;
              width: $px88;
            }
          }

          .txt-order {
            display: block;
            font-size: $px14;
            color: #767676;
            word-break: keep-all;
          }

          i {
            position: absolute;
            top: 50%;
            margin-top: $px-33;
            right: $px-5;
            font-size: $px24;
            color: #e1e1e1;
          }
        }
      }
    }

    &.add {
      padding: 0 !important;

      ul {
        margin-bottom: 0;

        li {
          border-radius: 0;
          border: 0;

          .card-header {
            border-bottom: 0;
            padding: 0;
            background: none;

            > button {
              &:focus {
                position: relative;
                box-shadow: none;
                z-index: 1;
              }

              i {
                transition: transform 0.18s, margin 0.18s;
              }

              &:not(.collapsed) {
                i {
                  margin-top: $px1;
                  transform: rotate(180deg);
                }
              }
            }
          }

          .card-body {
            padding: $px5 $px15 $px15 $px15;
            white-space: pre-line;
            word-break: keep-all;
          }

          button {
            text-align: left;
            padding: $px15;

            &:not(.collapsed) {
              font-weight: 500;
            }

            i {
              float: right;
              padding: $px3 0;
            }
          }

          &:last-child {
            border-bottom-width: $px1;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: $px25;
    }
  }

  &::v-deep {
    // 에디터에서 설정한 넓이, 높이 값이 적용되지 않아 주석 처리.
    //img {
    //  width: auto;
    //  height: auto;
    //}

    iframe {
      width: 100%;
    }
  }

  &.skeleton {
    .part.info {
      p > span, div {
        @include skeleton
      }
    }
  }

  @media (max-width: 767px) {
    .part.process .cont-process .list-order i {
      display: none;
    }
  }
}
</style>